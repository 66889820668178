<template>
  <li class="accordion__item">
    <div
        class="accordion__trigger"
        :class="{'accordion__trigger_active': visible}"
        @click="open">

      <slot name="accordion-trigger"></slot>
      <img src="~@/assets/img/arrow__faq.svg" alt="" class="accordion__trigger__img" :class="{'openTrigger': visible}">
    </div>

    <transition
        name="accordion"
        @enter="start"
        @after-enter="end"
        @before-leave="start"
        @after-leave="end">

      <div class="accordion__content"
           v-show="visible">
        <ul class="accordion__text">
          <slot name="accordion-content"></slot>
        </ul>
      </div>
    </transition>
  </li>
</template>

<script>
export default {
  name: "accordion-item",
  props: {},
  inject: ["Accordion"],
  data() {
    return {
      index: null,
    };
  },
  computed: {
    visible() {
      return this.index == this.Accordion.active;
    }
  },
  methods: {
    open() {
      if (this.visible) {
        this.Accordion.active = null;
      } else {
        this.Accordion.active = this.index;
      }
    },
    start(el) {
      el.style.height = el.scrollHeight + "px";

    },
    end(el) {
      el.style.height = "";
    }
  },
  created() {
    this.index = this.Accordion.count++;
  }
}
</script>

<style lang="scss" scoped>


.accordion__item {
  cursor: pointer;
  /*padding-top: 1.042vw;
  padding-left: 1.563vw;*/
  /*border-top: 0.052vw solid #FFFFFF;
  border-left: 0.052vw solid #FFFFFF;
  border-bottom: 0.052vw solid #FFFFFF;
  border-right: 0.052vw solid #FFFFFF;*/

  display: block;

  /*
  margin-bottom: 2.083vw ;
  */

  position: relative;
}


.accordion__trigger {
  width: 50.625vw;
  height: 5.781vw;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.accordion__trigger__img{
  width: 1.5vw;
  height: 1.5vw;
  /*position: absolute;
  top: 1.7vw;
  right: 1.7vw;*/
  transition:  0.5s;
  margin-right: 1vw;
}
.openTrigger{
  rotate: 270deg;
}
.accordion__text{
  font-family: 'Benzin-Regular';
  font-size: 0.938vw;
  color: #F2EFEF;
  line-height: 2.094vw;
  padding-left: 1.563vw;
  padding-bottom: 1.302vw;
  padding-right: 3.177vw;
  cursor: text;
  word-wrap: break-word;
}


/*.accordion__trigger:before{
    top: 1.4em;
    right: 1.3em;
    color: transparent;
    background: url("~@/assets/img/deteils__arrov.png") no-repeat 50% 50% / 1em 1em;
    width: 1vw;
    height: 1vw;
    content: "";
    position: absolute;
    transition: transform .5s;
}*/


.accordion-enter-active,
.accordion-leave-active {
  will-change: height, opacity;
  transition: height 0.3s ease, opacity 0.3s ease;
  overflow: hidden;
}

.accordion-enter,
.accordion-leave-to {
  height: 0 !important;
  opacity: 0;
}
@media screen and(max-width: 991px) {
  .accordion__trigger{
    width: 100%;
    height: 26.133vw;
  }
  .accordion__trigger__img{
    width: 5vw;
    height: 5vw;
    rotate: -90deg;
    transition: 0.5s;
    margin-right: 2vw;
  }
  .openTrigger{
    rotate: 0deg;
  }
  .accordion__text{
    font-size: 3.733vw;
    line-height: 4.480vw;
    padding-left: 5.333vw;
    padding-bottom: 5.333vw;
    padding-right: 3.467vw;
  }

}
</style>

